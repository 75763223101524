<template>
  <div class="draggable-box">
    <div
      v-if="advList.length"
      class="square"
      ref="moveRef"
      @mousedown="down()"
      @touchstart="down()"
      @mousemove.prevent.stop="move()"
      @touchmove.prevent.stop="move()"
      @mouseup="end()"
      @touchend="end()"
    >
      <div class="img_box" @click="goRouter">
        <!-- <ImgDecypt :src="advList[0].cover" /> -->
        <ImgDecypt :src="advList[0].pictureUrl" :key="advList[0].pictureUrl" />
      </div>
    </div>
  </div>
</template>
<script>
import { jumpAdv } from "@/utils/getAdv.js";
// import { jumpAdv } from "@/utils/getConfig";
export default {
  // components: {
  //   ImgDecypt: () => import("@/components/ImgDecypt/index.vue"),
  // },
  props: {
    advList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      flags: false,
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      let dom = document.querySelector(".defaultAdv");
      if (dom) {
        this.x = dom.offsetLeft;
        this.y = dom.offsetTop;
        this.show = true;
      }
    });
  },
  methods: {
    // 实现移动端拖拽
    down() {
      this.flags = true;
      let touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      let moveRef = this.$refs.moveRef;
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = moveRef.offsetLeft;
      this.dy = moveRef.offsetTop;
    },
    move() {
      if (this.flags) {
        let touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        let moveRef = this.$refs.moveRef;
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        //添加限制：只允许在屏幕内拖动
        const maxWidth = document.body.clientWidth - 100; //屏幕宽度减去悬浮框宽高
        const maxHeight = document.body.clientHeight - 80 - 70;
        if (this.xPum < 0) {
          //屏幕x限制
          this.xPum = 0;
        } else if (this.xPum > maxWidth) {
          this.xPum = maxWidth;
        }
        if (this.yPum < 0) {
          //屏幕y限制
          this.yPum = 0;
        } else if (this.yPum > maxHeight) {
          this.yPum = maxHeight;
        }
        moveRef.style.left = this.xPum + "px";
        moveRef.style.top = this.yPum + "px";
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    goRouter() {
      if (!this.advList.length) return;
      jumpAdv(this.advList[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .vdr {
  border: none;
}
.draggable-box {
}
.square {
  width: 100px;
  height: 100px;
  z-index: 10;
  position: absolute;
  bottom: 50px;
  right: 0px;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.55);
}
.img_box {
  width: 100px;
  height: 100px;
  overflow: hidden;
  pointer-events: auto;
  border-radius: 5px;
}
</style>
