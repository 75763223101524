<template>
  <div class="warp">
    <van-search v-model="search_value" show-action readonly placeholder="请输入搜索关键词" :left-icon="leftIcon" background="#f21313" shape="round" @click="toSearch">
      <template #action>
        <div class="server" @click.stop="server">
          <svg-icon iconClass="server"></svg-icon>
        </div>
      </template>
    </van-search>
  </div>
</template>
<script>
import { getCustom } from "@/api/index.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      search_value: "",
      leftIcon: require("@/assets/png/search_img.png"),
    };
  },
  methods: {
    toSearch() {
      this.$router.push("/search");
    },
    async server() {
      let toast = Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let ret = await this.$Api(getCustom);
      toast.clear();
      if (ret.code == 200) {
        let endpoint = ret.data.endpoint;
        location = this.$store.state.app.baseUrl + endpoint;
        return;
      }
      this.$toast(ret.tip || "加载客服失败");
    },
  },
};
</script>
<style lang="scss" scoped>
.van-search__action:active {
  background-color: transparent;
}
.warp {
  /deep/ .van-field__left-icon {
    display: flex;
    align-items: center;
    .van-icon {
      display: flex;
    }
  }
  .server {
    width: 30px;
    height: 30px;
    margin: 0 12px 0 0;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
