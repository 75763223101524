<template>
  <div>
    <TopSearch class="search" />
    <div class="tab flex-around">
      <div
        tag="div"
        @click="changetab('/home/introduce')"
        class="item"
        :class="{ active: $route.path == '/home/introduce' }"
      >
        推荐
      </div>
      <div
        tag="div"
        @click="changetab('/home/tag')"
        class="item"
        :class="{ active: $route.path == '/home/tag' }"
      >
        专区
      </div>
      <div
        tag="div"
        @click="changetab('/home/rank')"
        class="item"
        :class="{ active: $route.path == '/home/rank' }"
      >
        排行
      </div>
    </div>
    <div class="bottom-all">
      <transition :name="transitionName">
        <keep-alive>
          <router-view class="bottom-content" />
        </keep-alive>
      </transition>
    </div>
    <!-- <wordsann :words="words" :img="img" />

    <div class="appPop" v-if="showAppPop">
      <div class="appPopContent">
        <div class="appList">
          <div class="appItem" v-for="item in appAdvList" :key="item.id">
            <ImgDecypt
              class="appImage"
              :src="item.pictureUrl"
              :key="item.pictureUrl"
            />
            <div class="appDownload" @click="jumpAdd(item)">下载</div>
          </div>
        </div>
        <div class="appListClose" @click="showAppPop = false"></div>
      </div>
    </div> -->
    <DraggableAdv
      :advList="floatAdvList"
      v-show="floatAdvList.length"
    ></DraggableAdv>
    <!-- <div class="advBox" v-if="floatAdvList.length > 0 && showAdv">
      <div @click="jumpPage">
        <ImgDecypt class="floatAdv" :src="floatAdvList[0].pictureUrl"  />
      </div>
      <div class="adClose" @click="closeAd">
        <svg-icon icon-class="close1"/>
      </div>
    </div> -->
  </div>
</template>
<script>
// import wordsann from "@/views/widget/wordsAnn.vue";
import TopSearch from "@/components/TopSearch/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
import DraggableAdv from "@/components/DraggableAdv";
export default {
  name: "home",
  components: {
    TopSearch,
    // wordsann,
    DraggableAdv,
  },
  data() {
    return {
      transitionName: "", //路由切换
      ann: JSON.parse(sessionStorage.getItem("appConfig")).blts, //公告
      activity: JSON.parse(sessionStorage.getItem("appConfig")).ads, //活动
      // words: [], //文字公告
      // img: [], //图片公告
      // btnFlag: false,
      // appAdvList: [],
      // showAppPop: false,
      floatAdvList: [],
      showAdv: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  created() {
    this.floatAdvList = getAdItem(AdType.floatAd);
    // if (!sessionStorage.getItem("firstTime")) {
    //   this.appAdvList = getAdItem(AdType.appListAd);
    //   if (this.appAdvList.length > 0) {
    //     this.showAppPop = true;
    //   }
    //   //第一次进入
    //   sessionStorage.setItem("firstTime", "yes");
    //   let activitys = this.activity?.filter((item) => item.posType == 4);
    //   if (activitys?.length > 0) {
    //     this.img = this.img.concat(activitys[0].items);
    //   }
    //   let webPattern =
    //     /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/g;
    //   this.words = this.ann
    //     ?.filter((item) => {
    //       return item.type == 0;
    //     })
    //     .reverse(); //文字公告
    //   this.img = this.ann
    //     ?.filter((item) => {
    //       return item.type == 1;
    //     })
    //     .concat(this.img)
    //     .reverse(); //图片公告
    //   this.words.forEach((item) => {
    //     let url = item.content.match(webPattern)[0];
    //     item.content = item.content.replace(/\n/g, "<br/>");
    //     item.content = item.content.replace(
    //       webPattern,
    //       `<a href="https://${url}">${url}</a>`
    //     );

    //     item.content = item.content.replace(
    //       `https://<a href="https://${url}">${url}</a>/pfqipai`,
    //       `<a href="https://ptcc.in/pfqipai">https://ptcc.in/pfqipai</a>`
    //     );
    //     item.content = item.content.replace(
    //       `https://<a href="https://${url}">${url}</a>/ruoer`,
    //       `<a href="https://ptcc.in/ruoer">https://ptcc.in/ruoer</a>`
    //     );
    //   });
    // }
  },
  watch: {
    $route(to, from) {
      // window.removeEventListener('scroll', this.scrollToTop, true)
      // if (to.path == "/home/introduce" || to.path == "/home/tag" || to.path == "/home/rank") {
      //   window.addEventListener('scroll', this.scrollToTop, true)
      // }
      if (to.meta.index > from.meta.index) {
        this.transitionName = "slide-left";
      } else if (to.meta.index < from.meta.index) {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "";
      }
    },
  },
  methods: {
    closeAd() {
      this.showAdv = false;
      // console.log(123123)
    },
    jumpAdd(item) {
      jumpAdv(item);
    },
    changetab(path) {
      sessionStorage.setItem("homeTab", path);
      this.$router.push(path);
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    // backTop () {
    //     let dom = document.querySelector(".scroll-content");
    //     let timer = setInterval(() => {
    //       let ispeed = Math.floor(-this.scrollTop / 5)
    //       dom.scrollTop = dom.scrollTop = this.scrollTop + ispeed
    //       if (this.scrollTop === 0) {
    //         clearInterval(timer)
    //       }
    //     }, 16)
    // },
    scrollToTop() {
      let dom = document.querySelector(".scroll-content");
      this.scrollTop = dom.scrollTop;
      // console.log(this.scrollTop)
      if (this.scrollTop > 300) {
        this.btnFlag = true;
      } else {
        this.btnFlag = false;
      }
    },
    jumpPage() {
      jumpAdv(this.floatAdvList[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  position: relative;
}
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}
.tab {
  width: 100%;
  position: static;
  top: 0;
  background: #f21313;
  padding: 12px 0 7px;
  z-index: 11;
}
.item {
  font-size: 16px;
  color: #ffffff;
}
.active {
  font-size: 18px;
  position: relative;
  font-weight: bold;
}
.active::after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 20px;
  height: 3px;
  background: #ffffff;
  border-radius: 1px;
  bottom: -7px;
  left: 50%;
  margin-left: -10px;
}
.bottom-all {
  position: relative;
}
.bottom-content {
  position: absolute;
  width: 100%;
  padding-bottom: 30px;
}
.backTop {
  position: fixed;
  right: 8%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  background: url("../../assets/png/backTop.png");
  background-size: 100% 100%;
  z-index: 3;
}

.appPop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 15;
  padding: 0 28px;
  box-sizing: border-box;
}

.appPop .jumpOver {
  position: absolute;
  right: 32px;
  top: 47px;
  font-size: 18px;
  color: #ff810d;
  z-index: 11;
}
.appPop .appList {
  // max-height: calc(100% - 90px);
  // overflow-y: auto;
  // margin-top: 90px;
  /* margin-top: 2.88rem; */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: grid;
  grid-row-gap: 24px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.appPop .appItem {
}
.appPop .appItem .appImage {
  width: 92px;
  height: 92px;
  /* background: #00000000; */
  /* border-radius: 0.18rem !important; */
}
.appPop .appItem .appImage /deep/ .vanImage {
  background: #00000000 !important;
}
.appPop .appItem .appImage /deep/ img {
  object-fit: fill !important;
  border-radius: 9px;
}
.appPop .appDownload {
  width: 89px;
  height: 26px;
  line-height: 26px;
  border-radius: 6px;
  font-size: 16px;
  background: #f4454d;
  text-align: center;
  margin: 12px auto 0;
  color: #ffff;
}
.appPopContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.appListClose {
  width: 38px;
  height: 38px;
  background: url("../../assets/png/close3.png");
  background-size: 100% 100%;
  margin: 20px auto 0;
}

.advBox {
  position: fixed;
  bottom: 1.5rem;
  right: 0.2rem;
  width: 100px;
  height: 100px;
  .floatAdv {
    width: 100%;
    height: 100%;
  }
}
</style>
